import { Link } from 'gatsby'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { groupByN } from '../utils'
import Button from './Button'

const Warehousing = ({ data }) => {
  const { heading, subHeading, description, btnText, btnUri, items } = data
  return (
    <section className="warehousing">
      <Container fluid>
        <Row>
          <Col className="warehousing__col-left" xl={6}>
            <h2 className="warehousing__sub-heading">{subHeading}</h2>
            <h2 className="warehousing__heading">{heading}</h2>
            <div
              className="warehousing__text"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className="warehousing__button-wrapper">
              <Link to={btnUri}>
                <Button type="primary" label={btnText} />
              </Link>
            </div>
          </Col>
          <Col className="warehousing__col-right" xl={6}>
            {groupByN(2, items).map(groupedItems => (
              <div className="warehousing__wrapper">
                {groupedItems.map(item => (
                  <div className="warehousing__sub-wrapper" key={item.name}>
                    <div className="warehousing__line" />
                    <h3 className="warehousing__ter-heading">{item.name}</h3>
                    <div
                      className="warehousing__sub-text"
                      dangerouslySetInnerHTML={{ __html: item.details }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Warehousing

import { graphql, useStaticQuery } from 'gatsby'

const UseHomePageData = () => {
  const { wpPage } = useStaticQuery(
    graphql`
      query getHomePage {
        wpPage(
          uri: {
            eq: "/top-shipping-company-toronto-roadlinx-freight-container/"
          }
        ) {
          title
          seo {
            title
            metaDesc
          }
          homePage {
            heroData {
              title
              subTitle
              btnText
              btnUri
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      sizes
                    }
                  }
                }
              }
              tabs {
                name
                uri
                icon {
                  localFile {
                    childImageSharp {
                      fixed(width: 80) {
                        src
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
            about {
              heading
              subHeading
              description
              btnText
              btnLink
              items {
                name
                description
                icon {
                  localFile {
                    childImageSharp {
                      fixed(height: 62, width: 62) {
                        src
                        width
                        height
                      }
                    }
                  }
                }
                bgImage {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 500, maxHeight: 550) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
              }
            }
            services {
              heading
              subHeading
              description
              items {
                name
                btnUri
                details
                image {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
              }
            }
            shipping {
              heading
              subHeading
              btnText
              btnLink
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            shippingServices {
              heading
              subHeading
              description
              btnText
              btnUri
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 736) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      sizes
                    }
                  }
                }
              }
              icon {
                localFile {
                  childImageSharp {
                    fixed(height: 53, width: 81) {
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
            warehousing {
              heading
              subHeading
              description
              btnText
              btnUri
              items {
                name
                details
              }
            }
            reqQuote {
              heading
              description
              bgImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      sizes
                    }
                  }
                }
              }
              statCards {
                icon {
                  localFile {
                    childImageSharp {
                      fixed(height: 80, width: 77) {
                        src
                        width
                        height
                      }
                    }
                  }
                }
                label
                text
              }
              contact {
                heading
                phone
                fax
                email
                address
              }
            }
            freightServices {
              heading
              subHeading
              description
              btnText
              btnLink
              items {
                name
                description
                icon {
                  localFile {
                    childImageSharp {
                      fixed(height: 62, width: 62) {
                        src
                        width
                        height
                      }
                    }
                  }
                }
                bgImage {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 500, maxHeight: 550) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
              }
            }
            freightHanding {
              heading
              subHeading
              description
              btnText
              btnLink
              items {
                name
                description
                icon {
                  localFile {
                    childImageSharp {
                      fixed(height: 62, width: 62) {
                        src
                        width
                        height
                      }
                    }
                  }
                }
                bgImage {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 500, maxHeight: 550) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
              }
            }
            responsiveService {
              heading
              subHeading
              description
              btnText
              btnUri
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 736) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      sizes
                    }
                  }
                }
              }
              icon {
                localFile {
                  childImageSharp {
                    fixed(height: 79, width: 61) {
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
            personalizedApproach {
              heading
              subHeading
              description
              btnText
              btnUri
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 736) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      sizes
                    }
                  }
                }
              }
              icon {
                localFile {
                  childImageSharp {
                    fixed(height: 68, width: 95) {
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
            oceanShipping {
              heading
              subHeading
              description
              btnText
              btnLink
              bgImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 769, maxHeight: 904) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      sizes
                    }
                  }
                }
              }
              cards {
                icon {
                  localFile {
                    childImageSharp {
                      fixed(height: 50, width: 50) {
                        src
                        width
                        height
                      }
                    }
                  }
                }
                details
              }
            }
          }
        }
      }
    `
  )
  return wpPage
}

export default UseHomePageData

import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Button from './Button'

const Freequote = () => {
  const data = useStaticQuery(
    graphql`
      query getFreeQuote {
        wpPage(
          uri: {
            eq: "/top-shipping-company-toronto-roadlinx-freight-container/"
          }
        ) {
          homePage {
            freeQuote {
              heading
              btnText
              btnUri
              bgImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const { heading, btnText, btnUri, bgImage } = data.wpPage.homePage.freeQuote
  return (
    <BackgroundImage fluid={bgImage.localFile.childImageSharp.fluid}>
      <section className="free-quote">
        <div className="free-quote__overlay" />
        <div className="free-quote__wrapper">
          <div className="free-quote__sub-wrapper">
            <h1 className="free-quote__heading">{heading}</h1>
            <div>
              <Link to={btnUri}>
                <Button type="primary" label={btnText} />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </BackgroundImage>
  )
}

export default Freequote

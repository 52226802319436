import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import Button from './Button'

const OceanShipping = ({ data }) => {
  const { heading, subHeading, description, bgImage, cards } = data
  return (
    <section className="ocean-shipping">
      <div className="ocean-shipping__col-left">
        <div className="ocean-shipping__sub-heading">{subHeading}</div>
        <h2 className="ocean-shipping__heading">{heading}</h2>
        <div className="ocean-shipping__text" dangerouslySetInnerHTML={{ __html: description }} />

        <div className="ocean-shipping__sub-wrapper sdfsdfdsf">
          {cards.map((item, index) => (
            <div className="ocean-shipping__ter-wrapper" key={index}>
              <div className="ocean-shipping__icon">
                <Img fixed={item.icon.localFile.childImageSharp.fixed} />
              </div>
              <div
                className="ocean-shipping__text"
                dangerouslySetInnerHTML={{ __html: item.details }}
              />
            </div>
          ))}
        </div>

        <div className="ocean-shipping__button-wrapper">
          <Link to={data.btnLink}>
            <Button type="primary" label={data.btnText} />
          </Link>
        </div>
      </div>
      <div className="ocean-shipping__col-right">
        <BackgroundImage
          className="ocean-shipping__background"
          fluid={bgImage.localFile.childImageSharp.fluid}
        >
          <div className="ocean-shipping__image" />
        </BackgroundImage>
      </div>
    </section>
  )
}

export default OceanShipping

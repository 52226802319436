import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from 'react'

const BlogCard = props => {
  return (
    <div className="blog-card">
      <GatsbyImage 
        loading="eager"
        className="blog-card__image"
        image={getImage(props.image)}
        alt=""
      />
      <div className="blog-card__overlay" />
      <div className="blog-card__text-wrapper">
        <h3 className="blog-card__heading">{props.heading}</h3>
        <div
          className="blog-card__text"
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
        <Link className="blog-card__learn-more" to={props.link}>
          Learn more
        </Link>
      </div>
    </div>
  )
}

export default BlogCard

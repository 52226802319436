import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Button from '../components/Button'
import Footer from '../components/Footer'
import FormCard from '../components/FormCard'
import Freequote from '../components/Freequote'
import HalfCard from '../components/HalfCard'
import Header from '../components/Header'
import International from '../components/International'
import LatestNews from '../components/LatestNews'
import Layout from '../components/Layout'
import Location from '../components/Location'
import OceanShipping from '../components/OceanShipping'
import { UseHomePageData } from '../components/query'
import QuoteForm from '../components/QuoteForm'
import SEO from '../components/seo'
import SlideCard from '../components/SlideCard'
import TabButton from '../components/TabButton'
import WareHousing from '../components/Warehousing'

const IndexPage = () => {
  const { seo, homePage } = UseHomePageData()
  const {
    heroData,
    about,
    services,
    shipping,
    shippingServices,
    warehousing,
    reqQuote: estimate,
    freightServices,
    freightHanding,
    responsiveService,
    personalizedApproach,
    oceanShipping,
  } = homePage
  console.log(shipping);
  const [activeFormTab, setActiveFormTab] = useState(0)
  const [activeShipTab, setActiveShipTab] = useState('Canada')
  const getCities = (tabs, country) => {
    return tabs
      .find(tab => tab.country === country)
      .cities.map(city => city.text)
  }
  const getMapImage = (tabs, country) => {
    return tabs.find(tab => tab.country === country).image.localFile
      .childImageSharp.fluid
  }

  return (
    <Layout>
      <SEO title={seo?.title} description={seo?.metaDesc} />
      <Header data={heroData} />
      <section className="slide-card-section">
        <Container fluid>
          <div className="slide-card-section__upper-wrapper">
            <h2 className="slide-card-section__sub-heading slide-card-section__sub-text-center">
              {about?.subHeading}
            </h2>
            <h2 className="slide-card-section__heading slide-card-section__heading_about">
              {about?.heading}
            </h2>
            <div
              className="slide-card-section__text"
              dangerouslySetInnerHTML={{ __html: about?.description }}
            />
          </div>
        </Container>
        <Container fluid>
          <Row className="slide-card-section__row">
            {about.items.map(item => (
              <Col className="slide-card-section__col" xl={4} key={item?.name}>
                <SlideCard
                  image={item?.bgImage?.localFile?.childImageSharp?.fluid}
                  icon={item?.icon?.localFile?.childImageSharp?.fixed}
                  text={item?.name}
                  overlaytext={item?.description}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <International data={services} />
      <Container fluid className="ship-section">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={6}>
            <p className="section-tag">{shipping.subHeading}</p>
            <h2 className="ship-section__heading">{shipping.heading}</h2>
            <Link to={shipping.btnLink}>
              <Button type="primary" label={shipping.btnText} />
            </Link>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} className="p-4">
            <GatsbyImage 
                loading="eager"
                className="services__sidebysideImage"
                image={getImage(shipping?.image?.localFile)}
                alt={shipping?.image?.altText}
            />
          </Col>
        </Row>
      </Container>

      <section className="half-card-section">
        <Container fluid>
          <Row>
            <Col className="half-card-section__col-left" xl={6}>
              <HalfCard
                type="primary"
                image={shippingServices.image.localFile.childImageSharp.fluid}
                icon={shippingServices.icon.localFile.childImageSharp.fixed}
                text="24 hours"
                heading="Straight transportation"
              />
            </Col>
            <Col className="half-card-section__col-right" xl={6}>
              <div className="half-card-section__sub-heading">
                {shippingServices.subHeading}
              </div>
              <h2 className="half-card-section__heading">
                {shippingServices.heading}
              </h2>
              <div
                className="half-card-section__text"
                dangerouslySetInnerHTML={{
                  __html: shippingServices.description,
                }}
              />
              <div className="half-card-section__button-wrapper">
                <Link to={shippingServices.btnUri}>
                  <Button type="primary" label={shippingServices.btnText} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <WareHousing data={warehousing} />

      <BackgroundImage fluid={estimate.bgImage.localFile.childImageSharp.fluid}>
        <div className="estimate-section">
          <Container fluid className="h-100">
            <Row className="estimate-section__wrapper m-auto">
              <Col xs={12} xl={5} className="estimate-section__col-left">
                <h6 className="estimate-section__heading pb-4">
                  {estimate.heading}
                </h6>
                <p className="estimate-section__paragraph ">
                  <div
                    dangerouslySetInnerHTML={{ __html: estimate.description }}
                  />
                </p>
              </Col>
              <Col xl={6} className="estimate-section__col-right">
                {estimate.statCards.map(card => (
                  <div className="estimate-section__card" key={card.text}>
                    <Img
                      className="estimate-section__card-icon"
                      fixed={card.icon.localFile.childImageSharp.fixed}
                    />
                    <h4 className="estimate-section__card-title">
                      {card.label}
                    </h4>
                    <p className="estimate-section__card-text">{card.text}</p>
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        </div>
      </BackgroundImage>

      <Container className="estimate-forms negative-margin">
        <Row>
          <Col xs={{ span: 4, offset: 1 }} className="d-flex">
            <TabButton
              label="Request a quote"
              handleOnClick={index => setActiveFormTab(index)}
              index={1}
              active={activeFormTab === 1}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <FormCard Form={QuoteForm} data={estimate.contact} />
          </Col>
        </Row>
      </Container>
      <Container className="estimate-forms-mobile-view">
        <Row>
          <Col>
            <FormCard Form={QuoteForm} data={estimate.contact} />
          </Col>
        </Row>
      </Container>

      <section className="slide-card-section">
        <Container fluid>
          <div className="slide-card-section__upper-wrapper">
            <div className="slide-card-section__sub-heading">
              {freightServices.subHeading}
            </div>
            <h2 className="slide-card-section__heading">
              {freightServices.heading}
            </h2>
            <div
              className="slide-card-section__text"
              dangerouslySetInnerHTML={{ __html: freightServices.description }}
            />
          </div>
        </Container>
        <Container fluid>
          <Row className="slide-card-section__row">
            {freightServices.items.map(item => (
              <Col className="slide-card-section__col" xl={4} key={item.name}>
                <SlideCard
                  image={item.bgImage.localFile.childImageSharp.fluid}
                  icon={item.icon.localFile.childImageSharp.fixed}
                  text={item.name}
                  overlaytext={item.description}
                />
              </Col>
            ))}
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="slide-card-section__button-wrapper">
                <Link to={freightServices.btnLink}>
                  <Button type="primary" label={freightServices.btnText} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="slide-card-section">
        <Container fluid>
          <div className="slide-card-section__upper-wrapper">
            <div className="slide-card-section__sub-heading">
              {freightHanding.subHeading}
            </div>
            <h2 className="slide-card-section__heading">
              {freightHanding.heading}
            </h2>
            <div
              className="slide-card-section__text"
              dangerouslySetInnerHTML={{ __html: freightHanding.description }}
            />
          </div>
        </Container>
        <Container fluid>
          <Row className="slide-card-section__row">
            {freightHanding.items.map(item => (
              <Col className="slide-card-section__col" xl={4} key={item.name}>
                <SlideCard
                  image={item.bgImage.localFile.childImageSharp.fluid}
                  icon={item.icon.localFile.childImageSharp.fixed}
                  text={item.name}
                  overlaytext={item.description}
                />
              </Col>
            ))}
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col xl={12}>
              <div className="slide-card-section__button-wrapper">
                <Link to={freightHanding.btnLink}>
                  <Button type="primary" label={freightHanding.btnText} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="half-card-section">
        <Container fluid>
          <Row>
            <Col className="half-card-section__col-right" xl={6}>
              <HalfCard
                type="secondary"
                image={responsiveService.image.localFile.childImageSharp.fluid}
                icon={responsiveService.icon.localFile.childImageSharp.fixed}
                text="24/7"
                heading="Request Support"
              />
            </Col>
            <Col className="half-card-section__col-right" xl={6}>
              <div className="half-card-section__sub-heading">
                {responsiveService.subHeading}
              </div>
              <h2 className="half-card-section__heading">
                {responsiveService.heading}
              </h2>
              <div
                className="half-card-section__text"
                dangerouslySetInnerHTML={{
                  __html: responsiveService.description,
                }}
              />
              <div className="half-card-section__button-wrapper">
                <Link to={responsiveService.btnUri}>
                  <Button type="primary" label={responsiveService.btnText} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="half-card-section">
        <Container fluid>
          <Row>
            <Col className="half-card-section__col-right" xl={6}>
              <div className="half-card-section__sub-heading">
                {personalizedApproach.subHeading}
              </div>
              <h2 className="half-card-section__heading">
                {personalizedApproach.heading}
              </h2>
              <div
                className="half-card-section__text"
                dangerouslySetInnerHTML={{
                  __html: personalizedApproach.description,
                }}
              />
              <div className="half-card-section__button-wrapper">
                <Link to={personalizedApproach.btnUri}>
                  <Button type="primary" label={personalizedApproach.btnText} />
                </Link>
              </div>
            </Col>
            <Col className="half-card-section__last-col-right" xl={6}>
              <HalfCard
                type="tertiary"
                image={
                  personalizedApproach.image.localFile.childImageSharp.fluid
                }
                icon={personalizedApproach.icon.localFile.childImageSharp.fixed}
                text="100%"
                heading="Personalised approach"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <OceanShipping data={oceanShipping} />
      <LatestNews />
      <Freequote />
      <Location />
      <Footer />
    </Layout>
  )
}

export default IndexPage

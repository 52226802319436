import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Carousel, { consts } from 'react-elastic-carousel'
import leftarrow from '../images/left-arrow.svg'
import rightarrow from '../images/right-arrow.svg'
import { groupByN } from '../utils'
import CarouselMobile from './Homepage/International/CarouselMobile'
import TruckType from "./Homepage/International/TruckType"

class International extends Component {
  myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? '<' : '>'
    return (
      <button className="arrow" onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    )
  }

  render() {
    const { heading, subHeading, description, items } = this.props.data
    return (
      <section className="international">
        <Container fluid>
          <Row>
            <Col className="international__col">
              <div className="international__wrapper">
                <div className="international__heading_container">
                  <div className="international__sub-heading">{subHeading}</div>
                  <h1 className="international__heading">{heading}</h1>
                </div>
                <div
                  className="international__text"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="international__carousel-display" fluid>
          <Row>
            <Col>
              <div className="international__buttons-wrapper">
                <div
                  className="international__button"
                  onClick={() => this.carousel.slidePrev()}
                >
                  <img src={leftarrow} />
                </div>
                <div
                  className="international__button"
                  onClick={() => this.carousel.slideNext()}
                >
                  <img src={rightarrow} />
                </div>
              </div>
              <Carousel
                ref={ref => (this.carousel = ref)}
                className="international__carousel"
                renderArrow={this.myArrow}
                itemsToShow={1}
              >
                {groupByN(4, items).map((groupedItems, i) => (
                  <div className="international__carousel-wrapper" key={i}>
                    {groupedItems.map(item => (
                      <TruckType
                        key={item.name}
                        image={item.image.localFile.childImageSharp.fluid}
                        heading={item.name}
                        text={item.details}
                        link={item.btnUri}
                      />
                    ))}
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
        <Container
          className="international__carousel-display-mobile"
          fluid
        >
          <Row>
            <Col>
              <CarouselMobile data={items} />
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default International

import { graphql, useStaticQuery } from 'gatsby'

const UseServicePageData = () => {
  const { wpPage } = useStaticQuery(
    graphql`
      query SERVICESPAGE {
        wpPage(uri: { eq: "/services/" }) {
          Services {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title
            heroBottom {
              heading
              subHeading
            }
            overview
            benefits
            price
            allServices {
              url
              serviceName
              serviceImage {
                altText
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return wpPage
}

export default UseServicePageData

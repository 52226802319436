import Img from 'gatsby-image'
import React from 'react'

const HeaderButton = props => {
  const { label, image } = props

  return (
    <div className="header-button">
      <div className="header-button__wrapper">
        <Img fixed={image} />
        <h2 className="header-button__text">{label}</h2>
      </div>
    </div>
  )
}

export default HeaderButton

import { graphql, StaticQuery } from 'gatsby'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Carousel, { consts } from 'react-elastic-carousel'
import leftarrow from '../images/left-arrow.svg'
import rightarrow from '../images/right-arrow.svg'
import BlogCard from './BlogCard'

export default class LatestNews extends Component {
  constructor(props) {
    super(props)
    this.breakPoints = [
      {
        width: 320,
        itemsToShow: 3,
        verticalMode: true,
        itemsToScroll: 1,
        pagination: false,
      },
      {
        width: 576,
        itemsToShow: 3,
        verticalMode: true,
        itemsToScroll: 1,
        pagination: false,
      },
      {
        width: 768,
        itemsToShow: 3,
        verticalMode: true,
        itemsToScroll: 1,
        pagination: false,
      },
      {
        width: 992,
        itemsToShow: 2,
        verticalMode: true,
        itemsToScroll: 1,
        pagination: false,
      },
      { width: 1200, itemsToShow: 2, itemsToScroll: 1, pagination: true },
      { width: 1920, itemsToShow: 2, itemsToScroll: 1, pagination: true },
    ]
  }

  myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? '<' : '>'
    return (
      <button className="arrow" onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    )
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query LatestNewsQuery {
            allWpPost(sort: { fields: date, order: DESC }, limit: 6) {
              edges {
                node {
                  id
                  title
                  excerpt
                  authorId
                  date(formatString: "MMMM DD, YYYY")
                  slug
                  uri
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ allWpPost }) => (
          <section className="latest-news">
            <Container fluid>
              <Row>
                <Col xl={12}>
                  <h2 className="latest-news__heading">
                    Freight Shipping News
                  </h2>
                </Col>
              </Row>
            </Container>
            <Container fluid>
              <Row>
                <Col className="latest-news__col" xl={12}>
                  <div className="latest-news__buttons-wrapper">
                    <div
                      className="latest-news__button"
                      onClick={() => this.carousel.slidePrev()}
                    >
                      <img src={leftarrow} alt="" />
                    </div>
                    <div
                      className="latest-news__button"
                      onClick={() => this.carousel.slideNext()}
                    >
                      <img src={rightarrow} alt="" />
                    </div>
                  </div>
                  <Carousel
                    ref={ref => (this.carousel = ref)}
                    breakPoints={this.breakPoints}
                    renderArrow={this.myArrow}
                    renderPagination={({ pages, activePage, onClick }) => {
                      return (
                        <div className="pagination">
                          {pages.map(page => {
                            const isActivePage = activePage === page
                            return (
                              <div
                                key={page}
                                onClick={() => onClick(page)}
                                active={isActivePage}
                              />
                            )
                          })}
                        </div>
                      )
                    }}
                  >
                    {allWpPost.edges.map(({ node }) => (
                      <BlogCard
                        image={node.featuredImage.node.localFile}
                        heading={node.title}
                        text={node.excerpt}
                        link={node.uri}
                      />
                    ))}
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      />
    )
  }
}

// export const pageQuery = graphql`

// `

import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import Button from './Button'
import HeaderButton from './HeaderButton'


const Header = ({ data }) => {
  const { title, subTitle, image, btnText, btnUri, tabs } = data
  return (
    <BackgroundImage fluid={image?.localFile?.childImageSharp?.fluid}>
      <section className="header">
        <h1 className="header__title">{title}</h1>
        <div className="header__text">{subTitle}</div>
        <Link to={btnUri}>
          <Button type="primary" label={btnText} />
        </Link>
        <div className="header__buttons-wrapper">
          {tabs?.map(tab => (
            <Link to={tab?.uri} key={tab?.uri}>
              <HeaderButton
                image={tab?.icon?.localFile?.childImageSharp?.fixed}
                label={tab?.name}
              />
            </Link>
          ))}
        </div>
      </section>
    </BackgroundImage>
  )
}

export default Header

import React from 'react'
import AliceCarousel from 'react-alice-carousel'

import TruckType from './TruckType'

const CarouselMobile = ({ data }) => {
  const items = data.map(item => (
    <TruckType
      onDragStart={e => e.preventDefault()}
      image={item.image.localFile.childImageSharp.fluid}
      heading={item.name}
      text={item.details}
    />
  ))
  return (
    <div>
      <AliceCarousel mouseTracking items={items} />
    </div>
  )
}

export default CarouselMobile

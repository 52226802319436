import React from 'react'
import Img from 'gatsby-image'

const SlideCard = props => {
  return (
    <div className="slide-card">
      <Img fluid={props.image} className="slide-card__image" />
      <div className="slide-card__overlay" />
      <div className="slide-card__icon-wrapper">
        <Img fixed={props.icon} className="slide-card__icon" />
        <h3 className="slide-card__text">{props.text}</h3>
      </div>
      <div className="sec-overlay">
        <div className="sec-overlay-wrapper">
          <Img fixed={props.icon} className="sec-overlay-icon" />
          <h3 className="sec-overlay-text">{props.text}</h3>
          <div
            className="sec-overlay-sub-text"
            dangerouslySetInnerHTML={{ __html: props.overlaytext }}
          />
        </div>
      </div>
    </div>
  )
}

export default SlideCard

import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import phone from '../images/phone.svg'
import map from '../images/map.svg'
import IconText from './IconText'
import Button from './Button'

const FormCard = ({ Form, data }) => (
  <div className="form-card__wrapper">
    <Container className="h-100">
      <Row className="h-100">
        <Col lg={8} xl={8}>
          <Form />
        </Col>
        <Col lg={4} xl={4}>
          <div className="card__wrapper">
            <h4 className="card__heading">{data.heading}</h4>
            <div className="d-flex flex-column justify-content-around flex-grow py-4">
              <IconText text={`${data.phone}`} icon={phone} />
              <IconText text={`${data.fax}`} icon={phone} />
              <IconText text={`${data.address}`} icon={map} />
            </div>
            <a href={`mailto:${data.email}`}>
              <Button type="tertiary" label="Write us" />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default FormCard

import Button from '@material-ui/core/Button'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as Yup from 'yup'

const QuoteForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const serviceTypes = [
    'Select Service',
    'Truckload (FTL)',
    'Less than Truckload (LTL)',
    'Reefer & Heated',
    'Flatbed Freight',
    'Warehousing & Crossdocking',
    'Trade Show',
    'Expedite Services',
    'Dry Van Freight',
  ]
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('*Please enter your name'),
    email: Yup.string()
      .required('*Please enter your email')
      .email('*Please enter valid email'),
    phone: Yup.string()
      .required('*Please enter your phone number'),
    serviceType: Yup.string().required('*Please select a service'),
    message: Yup.string(),
  })
  const initValues = {
    fullName: '',
    email: '',
    phone: '',
    serviceType: '',
    message: '',
  }
  return (
    <Container className="quote-form__wrapper">
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          const formData = {
            input_1: data.fullName,
            input_2: data.email,
            input_3: data.phone,
            input_4: data.serviceType,
            input_5: data.message,
          }
          const url =
            'http://api.roadlinx.com/wp-json/gf/v2/forms/2/submissions/'
          const auth = btoa(
            `${process.env.GATSBY_GRAVITY_CLIENT_KEY}:${process.env.GATSBY_GRAVITY_CLIENT_SECRET}`
          )
          const headers = {
            Authorization: `Basic ${auth}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
          }
          const options = { method: 'POST', headers }
          fetch(url, {
            ...options,
            body: JSON.stringify(formData),
          })
            .then(res => {
              if (res.ok) return res.json()
              
                console.log(res)
                throw new Error('Something went wrong!')
              
            })
            .then(res => {
              console.log(res)
              setSubmitting(false)
              setIsSubmitted(true)
              resetForm()
              setTimeout(() => setIsSubmitted(false), 5000)
            })
            .catch(err => {
              console.log(err)
              setSubmitting(false)
            })
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <form className="get-quote__form" onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <h3>Details</h3>
              </Col>

              <Col xs={12}>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="get-quote__input"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.fullName && touched.fullName && (
                  <div className="get-quote__error">{errors.fullName}</div>
                )}
              </Col>
              <Col md={6}>
                <input
                  type="email"
                  placeholder="Email Address"
                  className="get-quote__input"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <div className="get-quote__error">{errors.email}</div>
                )}
              </Col>
              <Col md={6}>
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="get-quote__input"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone && touched.phone && (
                  <div className="get-quote__error">{errors.phone}</div>
                )}
              </Col>
              <Col xs={12}>
                <select
                  labelId="service-select"
                  id="demo-simple-select"
                  className="get-quote__input"
                  fullWidth
                  name="serviceType"
                  value={values.serviceType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {serviceTypes.map(value => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                    ))}
                </select>
                {errors.serviceType && touched.serviceType && (
                <div className="get-quote__error">{errors.serviceType}</div>
                  )}
              </Col>
              <Col xs={12}>
                <input
                  type="text"
                  placeholder="Your Message"
                  className="get-quote__input"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>
              <Col xs={12} className="mt-5">
                <div className="quote-form__button-wrapper">
                  <Button
                    fullWidth
                    className="button primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Get a Quote'}
                  </Button>
                </div>
              </Col>
              {isSubmitted && (
              <div className="get-quote__success">
                Success! We have received your response and will get back to you
                soon.
              </div>
            )}
            </Row>
          </form>
        )}
      </Formik>
    </Container>
  )
}

export default QuoteForm

import React from 'react'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const TruckType = props => {
  const { image, heading, text, link } = props
  return (
    <div className="truck-type">
      <BackgroundImage className="truck-type__image" fluid={image} />
      <div className="truck-type__wrapper">
        <h1 className="truck-type__heading">{heading}</h1>
        <div
          className="truck-type__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <Link to={link} className="truck-type__link">
          Read more
        </Link>
      </div>
    </div>
  )
}

export default TruckType
